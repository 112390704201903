import * as React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useHighlightHandlers } from '../../contexts/HighlightContext/Highlight';
import { Typography as MuiTypography } from '@mui/material';
import { Button as MuiButton } from '@mui/material';
import CardContent from '@mui/material/CardContent';

export interface CardContentProps {
  title?: string | undefined;
  description?: string | undefined;
  limit?: number | undefined;
  className?: string;
}

const Typography = React.forwardRef<HTMLInputElement, any>((props, ref) =>
  useHighlightHandlers(MuiTypography, { forwardedRef: ref, ...props })
);

const Button = React.forwardRef<HTMLButtonElement, any>((props, ref) =>
  useHighlightHandlers(MuiButton, { forwardedRef: ref, ...props })
);

const CardContentStyled = styled(CardContent)(({ theme }) => ({
  // paddingBottom: `${theme.spacing(3)} !important`,
  width: '100%',
  justifyContent: 'space-between',
  [theme.breakpoints.up('sm')]: {
    position: 'relative',
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    textAlign: 'left',
    padding: theme.spacing(3, 4),
    height: '100%',
    border: theme.theme_component_card_content_border || 'none',
    borderTop: 'none',
    pointerEvents: 'none',
  },
})) as typeof CardContent;

const CardTitle = styled(Typography)(({ theme }) => ({
  width: '100%',
  display: 'inline-block',
  paddingBottom: theme.spacing(1),
  fontSize: theme.spacing(2.5),
  fontWeight: 'bold',
  color: theme.theme_component_navigation_bar_background_color,
  whiteSpace: 'inherit'
}));

const Description = styled(Typography)(({ theme }) => ({
  height: 'auto',
  fontSize: theme.spacing(2),
  color: '#6E6E6E',
  lineHeight: '28px',
  [theme.breakpoints.down('sm')]: {
    minHeight: '140px'
  },
  [theme.breakpoints.up('lg')]: {
    height: '80px'
  },
  [theme.breakpoints.up('md')]: {
    [theme.breakpoints.down('lg')]: {
      height: '70px',
      lineHeight: '20px',
    }
  }
}));

const ButtonStyled = styled(Button)(({ theme }) => ({
  display: 'inline-block',
  width: '104px',
  height: '40px',
  margin: 0,
  marginTop: theme.spacing(3),
  padding: theme.spacing(0),
  borderRadius: '8px',
  border: '1px solid #ebebeb',
  fontSize: "16px",
  fontWeight: "bold",
  color: theme.theme_component_card_content_button_color || theme.palette.primary.contrastText,
  background: theme.theme_component_card_content_button_background || theme.palette.primary.main,
  textTransform: 'none'
}));

const getText = (val: string, limit: number) => {
  if (val !== '' && val !== null && val !== undefined && val?.length > limit) {
    return `${val.slice(0, limit - 3)}...`;
  }
  return val;
};

// Todo: HUZ2ERA-1101 - Allow Typography props to be drilled (now we have them hardcoded in our jsx).
//       Had trouble extending our CardContentCProps...
export const CardContentF = (props: CardContentProps) => {
  const { description, title, limit, className, ...other } = props;
  const { t } = useTranslation('card_content_f');
  const body2Ref = React.useRef<HTMLInputElement | null>(null);
  const buttonRef = React.useRef<HTMLButtonElement | null>(null);

  return (
    <CardContentStyled className={className}>
      <CardTitle
        variant="caption"
        component="span"
        noWrap
        {...other}
      >
        {getText(title || "", limit || 35)}
      </CardTitle>
      <Description ref={body2Ref} variant="body2" component="p" color="textPrimary" {...other}>
        {description && getText(description, limit || 80)}
      </Description>
    </CardContentStyled>
  );
};
